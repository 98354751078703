
// Components
import Header from "@components/header";

// Assets
import { ReactComponent as ShopperTrustIndex } from "@images/sti-illustration.svg";
import { ReactComponent as PaymentPersonalisation } from "@images/payment-personalisation-illustration.svg";
import { ReactComponent as CheckoutRefundManager } from "@images/crm-illustration.svg";

const UseCasesGuide = () => {
  return (
    <div className="gradient-3 relative">
      <div className="-z-10 absolute inset-0 bg-product-select blur-[17px]"></div>
      <div className="mx-auto h-screen flex flex-col">
        <Header title="Use cases" showDemoLink={false} showBackButton />
        <div className="container-auth flex-1 flex items-center justify-center gap-6">
          <div>
            <div className="gradient-6 radius-1 min-h-[550px] w-full max-w-[412px] flex flex-col relative overflow-hidden">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <h1 className="header3 border-header text-white px-10 h-16 z-10">
                Consumer fraud risk
              </h1>
              <div className="px-10 flex flex-col justify-center min-h-[198px]">
                <p className="mt-4 paragraph-large text-white z-10 flex-1">
                  Embed consumer fraud risk intelligence to prevent fraud
                </p>

                <a
                  href="https://trudenty.notion.site/Consumer-fraud-risk-44b1ae49a4fa4326ba151459d6695720"
                  className="py-4 bg-grey-280/50 rounded-full w-full text-center text-white z-10 header5"
                >
                  Learn more
                </a>
              </div>
              <ShopperTrustIndex className="z-10 mx-auto absolute bottom-0 right-0" />
            </div>
          </div>
          <div>
            <div className="gradient-6 radius-1 min-h-[550px] w-full max-w-[412px] flex flex-col relative overflow-hidden">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <h1 className="header3 border-header text-white px-10 h-16 z-10">
                Personalise payments
              </h1>
              <div className="px-10 flex flex-col justify-center min-h-[198px]">
                <p className="mt-4 paragraph-large text-white z-10 flex-1">
                  Personalise payments to enhance experience, drive loyalty and
                  retention; whilst preventing fraud
                </p>

                <a
                  href="https://trudenty.notion.site/Personalise-payments-17638dcb7f68493bb84495ed68779f88"
                  className="py-4 bg-grey-280/50 rounded-full w-full text-center text-white z-10 header5"
                >
                  Learn more
                </a>
              </div>
              <PaymentPersonalisation className="z-10 mx-auto absolute bottom-0 right-0" />
            </div>
          </div>
          <div>
            <div className="gradient-6 radius-1 min-h-[550px] w-full max-w-[412px] flex flex-col relative overflow-hidden">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <h1 className="header3 border-header text-white px-10 h-16 z-10">
                Refunds decisioning
              </h1>
              <div className="px-10 flex flex-col justify-center min-h-[198px]">
                <p className="mt-4 paragraph-large text-white z-10 flex-1">
                  Offer instant refunds to trusted shoppers and enable assisted
                  refund decision making for not-trusted shoppers
                </p>

                <a
                  href="https://trudenty.notion.site/Refunds-decisioning-9802eddd9cf240ac9743c1751cbf973c"
                  className="py-4 bg-grey-280/50 rounded-full w-full text-center text-white z-10 header5"
                >
                  Learn more
                </a>
              </div>
              <CheckoutRefundManager className="z-10 mx-auto absolute bottom-0 right-0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCasesGuide;
