import React from "react";
import { Link } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";

// Components
import Button from "@button/Button";

// Assets
import { ReactComponent as TrudentyLogo } from "@icons/trudentyLogoWhite.svg";
import { ReactComponent as InstagramLogo } from "@icons/instagram.svg";
import { ReactComponent as TwitterLogo } from "@icons/twitter.svg";
import { ReactComponent as LinkedInLogo } from "@icons/linkedin.svg";
import { getClassNames } from "@/utils/helpers";

interface formData {
  email: string;
  firstName: string;
  lastName: string;
}

const Footer = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<formData>({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const onSubmit: SubmitHandler<
    {
      email: string;
      firstName: string;
      lastName: string;
    }> = async (data: {
      email: string;
      firstName: string;
      lastName: string;
    }) => {
      axios
        .post(
          `https://sandbox.two.internal.trudenty.com/api/waitlist/add`,
          {
            user: { ...data, type: "SUBSCRIBER" },
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(function () {
          toast.success("Thanks! We’ll keep you updated on everything Trudenty.");
          reset();
        })
        .catch(function (error) {
          toast.error(error || error?.message);
        });
    };

  return (
    <div className="w-full bg-blacked/[0.94] py-4">
      <div className="container-auth flex items-center justify-between">
        <div className="flex justify-between items-center w-1/2">
          <TrudentyLogo />

          <form onSubmit={handleSubmit(onSubmit)} id="subscribe">
            <div className="flex flex-1 items-center gap-x-2 w-full">
              <input
                type="email"
                {...register("email", { required: true })}
                placeholder="Join our mail list"
                className={getClassNames("input min-w-[302px] text-gray-100 text-sm self-stretch leading-4",
                  "lg:text-base lg:leading-5 py-1 px-2 md:px-3 lg:py-2 lg:px-4",
                  "rounded bg-gray-900 border border-gray-120 flex-1 max-w-[293px]",
                  "bg-[#2C2C2C] border-[#4B4B4B]")}
              />
              <Button
                type="submit"
                title="Subscribe"
                disabled={!isValid}
                color="primary"
                className="text-gray-100 bg-fair-black-2"
              />
            </div>
          </form>
        </div>
        <div className="flex items-center gap-x-2">
          <Link to="https://twitter.com/trudenty">
            <TwitterLogo />
          </Link>
          <Link to="https://www.instagram.com/trudentyhq/">
            <InstagramLogo />
          </Link>
          <Link to="https://www.linkedin.com/company/trudenty">
            <LinkedInLogo />
          </Link>
        </div>

      </div>
    </div>
  );
};

export default Footer;
