import { Link } from "react-router-dom";

// Components
import Header from "@components/header";

const SelectDemo = () => {
  const bullets = [
    "Embed consumer intelligence to prevent fraud",
    "Personalised payments",
    "Deploy automated and assisted refunds decisioning",
  ];

  return (
    <div className="gradient-3 relative">
      <div className="-z-10 absolute inset-0 bg-product-select blur-[17px]"></div>
      <div className="mx-auto h-screen flex flex-col">
        <Header title="Select a Product Demo" />
        <div className="container-auth flex-1 flex items-center justify-center gap-6">
          <Link to="/use-cases">
            <div className="bg-box-merchant bg-no-repeat bg-cover radius-1 min-h-[550px] w-full max-w-[412px] flex flex-col relative overflow-hidden">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <h1 className="header3 border-header text-white px-10 h-16 z-10">
                Console
              </h1>
              <p className="px-10 mt-4 paragraph-large text-white z-10">
                Use the Trudenty console to explore the use-cases;
              </p>
              <div className="py-4 px-10 lg:px-14">
                <ul className="relative flex flex-col gap-y-2 list-none">
                  {bullets.map((bullet) => (
                    <li
                      key={bullet}
                      className="bulleted paragraph-large text-white"
                    >
                      {bullet}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Link>
          <a href="https://mastercardconsumerdemo.trudenty.com">
            <div className="bg-person-consumer bg-no-repeat bg-cover radius-1 min-h-[550px] w-full max-w-[412px] flex flex-col relative overflow-hidden">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <h1 className="header3 border-header text-white px-10 h-16 z-10">
                Consumer
              </h1>
              <p className="px-10 mt-4 paragraph-large text-white z-10">
                Explore the resulting consumer experience for the different use
                cases
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SelectDemo;
