import React from 'react'

// Components
import Footer from '@components/footer'
import Header from '@components/header'

// Assets
import { ReactComponent as CalendarIcon } from '@icons/calendar.svg'
import { ReactComponent as QrCode } from '@images/qrcode.svg'
import { ReactComponent as EarthIcon } from '@icons/earth.svg'

const ContactUS = () => {
  return (
    <div className="gradient-2 h-screen flex flex-col">
      <div className='container-auth  mx-auto flex-1'>
        <Header title='' />
        <div className="flex justify-between mt-5">
          <div className="max-w-[694px]">
            <h1 className="header1 text-blacked h-20">Contact us</h1>
            <p className="paragraph-large text-grey-content-secondary">
              Interested in integrating fraud risk intelligence into your checkout and returns journeys? <br />
              <br />
              Scan the QR code to schedule a call or visit our website to learn more.
            </p>
            <div className="flex items-center gap-x-4 mt-6 bg-white4 border border-stroke rounded-lg p-3">
              <EarthIcon />
              <span className="text-black text-xl">trudenty.com</span>
            </div>

            <div className="grid grid-cols-2 mt-8 pb-5 border-b border-igrey-15">
              <span className="text-black text-xl">Address</span>
              <p className="paragraph-large text-grey-270 max-w-[397px]">
                Level 18, 40 Bank Street, Canary Wharf,
                London, United Kingdom, E145NR.
              </p>
            </div>
            <div className="grid grid-cols-2 mt-8 pb-5">
              <span className="text-black text-xl">Email</span>
              <p className="paragraph-large text-grey-270 max-w-[397px]">
                contact@trudenty.com
              </p>
            </div>

          </div>
          <div className="flex flex-col items-center">
            <QrCode className='rounded-[32px] mt-8 max-w-[323px]' />
            <div className="flex items-center gap-x-4 mt-6 bg-white4 border border-grey-stroke-tertiary rounded-lg py-3 px-6">
              <CalendarIcon />
              <span className="text-black text-xl">Scan the QR code to schedule a call</span>
            </div>
          </div>
        </div>

      </div><Footer /></div>
  )
}

export default ContactUS