import React from "react"

type ButtonProps = {
    title: string,
    category?: "1" | "2" | "3",
    hero?: boolean,
    color?: "primary" | "secondary" | "",
    className?: string,
    titleStyle?: string,
    onClick?: () => void,
    disabled?: boolean,
    type?: "button" | "submit",
    loading?: boolean,
    btnRef?: React.RefObject<HTMLButtonElement>
}

export const Button: React.FC<ButtonProps> = ({
    title,
    category = "1",
    hero=false,
    color = "primary",
    className = "",
    titleStyle="",
    onClick,
    disabled = false,
    type,
    loading,
    btnRef
}) => {
    const categoryType = {
        "1": "py-2 px-4 rounded-[50px]",
        "2": "py-1 px-3 rounded leading-5",
        "3": "py-2 px-6 w-full rounded-[50px]",
    }
    const scheme = {
        "primary": "bg-black text-grey border-0",
        "secondary": "bg-grey-100 text-brand-black border-0",
        "": ""
    }

    return (
        <button
            ref={btnRef}
            type={type || "button"}
            tabIndex={disabled ? -1 : 0}
            onClick={disabled ? () => { } : onClick}
            className={`whitespace-nowrap flex items-center justify-center gap-x-1 h-fit font-semibold 
                ${categoryType[category]} ${scheme[color]} ${className} ${disabled ? "cursor-not-allowed" : ""} 
                ${loading ? "cursor-wait" : ""}`}>
            <span className={`-tracking-[0.2px] ${hero ? "py-2 text-lg leading-[22px]" : ""} ${titleStyle}`}>{title}</span>
        </button>
    )
}

export default Button