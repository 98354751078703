import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// Pages
import ContactUS from "@pages/contact-us";
import DataSharing from "@pages/data-sharing";
import ExploreTheNetwork from "@pages/explore";
import Menu from "@pages/menu";
import Overview from "@pages/overview";
import SelectDemo from "@pages/select-demo";

import UseCases from "@pages/use-cases";
import UseCasesGuide from "@pages/use-cases-guide";
import ValueProposition from "@pages/value-proposition";

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Menu />} />
          <Route path="/value-proposition" element={<ValueProposition />} />
          <Route path="/contact-us" element={<ContactUS />} />
          <Route path="/select-demo" element={<SelectDemo />} />
          <Route path="/explore-demo" element={<ExploreTheNetwork />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/data-sharing" element={<DataSharing />} />
          <Route path="/use-cases" element={<UseCases />} />
          <Route path="/use-cases-guide" element={<UseCasesGuide />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
    </Router>
  );
}

export default App;
